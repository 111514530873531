<template>
  <UiPopup
    :model-value="modelValue"
    title="Cancel token payment"
    primary-button-text="Cancel payment token"
    secondary-button-text="Keep on hold"
    size="small"
    :loading="isLoading"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="text-body flex flex-col gap-6">
      <p>
        After canceling token payment, unit will be released <br />
        from 'Hold' and will be available for reservation by <br />
        all agents again.
      </p>

      <p>
        Cancel the payment pending and release the unit <br />
        from hold?
      </p>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { UnitPaymentToken } from '~/types/properties'

const emits = defineEmits(['update:modelValue', 'submit'])

type Props = {
  modelValue: boolean
  token: UnitPaymentToken
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()

const isLoading = ref(false)

const submit = async () => {
  try {
    isLoading.value = true

    await useCancelPaymentToken(props.token.id)

    emits('update:modelValue', false)

    uiStore.showSnackBanner('Payment token canceled successfully', 'success')
  } catch (error) {
    uiStore.showSnackBanner('Failed to cancel payment token, please try again later', 'error')
  } finally {
    isLoading.value = false

    emits('submit')
  }
}
</script>

<style scoped></style>
